import React, { useEffect, useRef, useState } from "react";
import { getupdateParticipant } from "../../../utils/Api";
import $ from "jquery";

const EventsForm = () => {
  const iframeRef = useRef(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    updateParticipant();
  }, []);

  const updateParticipant = () => {
    getupdateParticipant()
      .then((res) => {
        console.log("get update participant list", res);
        if (res?.code == 200) {
          setData(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    $('[data-cy="button-component"]').click(function () {
      alert("ddgdgd");
    });
    const handleMessage = (event) => {
      if (event.source === iframeRef.current.contentWindow) {
        const formData = event.data;
        console.log("Received form data:", formData);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="px-5">
      <div className="data-table px-5 py-4">
        <iframe
          ref={iframeRef}
          src={data?.passport_update}
          width="100%"
          height="1070"
        ></iframe>
      </div>
    </div>
  );
};

export default EventsForm;
