import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
// import {DcumentManagerWrapper} from '../pages/dashboard/DcumentManagerWrapper'
import { DcumentManagerWrapper } from "../pages/dashboard/FileManager/DocumentManagerWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import AdminDashboard from "../../admin/dashboard/Dashboard";
import EventsForm from "../pages/Events/Event";
import { NewPassword } from "../modules/auth/components/ResetPassword";
import Membershipdash from "../pages/membershipDashboard/Membershipdash";
import UserList from "../../admin/dashboard/Userlist";
import OverallPoint from "../../admin/Overall";
import Username from "../pages/username";
import DashboardWrapper from "../pages/dashboard/DashboardWrapper";
import Missingpoint from "../pages/report/missingpoint";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="admin-dashboard" element={<AdminDashboard />} />
        <Route path="user-list" element={<UserList />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="trails" element={<Membershipdash />} />
        <Route path="documentManager" element={<DcumentManagerWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route path="update-account" element={<EventsForm />} />
        <Route path="report/missing-points" element={<Missingpoint />} />
        <Route path="overall-points/:id" element={<OverallPoint />} />

        <Route path="username" element={<Username />} />
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
