import React, { useEffect, useState } from "react";
import {
  getParticipant,
  getParticipantPoints,
  getProfile,
} from "../../../utils/Api";

const DashboardWrapper = () => {
  const [profile, setProfile] = useState([]);
  const [points, setPoints] = useState(true);
  const [selected, setSelected] = useState([{ type: "points" }]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getProfileData();
    getActiveCompletion();
  }, []);

  const handleLoyaltyClick = (type, index) => {
    let options = selected;
    options[index].type = type;
    setSelected([...options]);
  };

  const getActiveCompletion = () => {
    setLoading(true);
    getParticipant()
      .then((response) => {
        setLoading(false);
        console.log("get participant pointssssssssssssssss", response);
        if (response?.code == 200) {
          const data = response?.data?.[0]?.title_submenu?.points_earned;
          const sortedData = [...data].sort(
            (a, b) => parseInt(a.total_points) - parseInt(b.total_points)
          );
          setData(sortedData?.reverse());
          let options = [];
          for (
            let i = 0;
            i < response?.data?.[0]?.title_submenu?.points_earned?.length;
            i++
          ) {
            options.push({ type: "points" });
          }
          setSelected(options);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getProfileData = () => {
    // setLoading(true);
    getProfile()
      .then((res) => {
        // setLoading(false);
        if (res?.code == 200) {
          setProfile(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            {/* <Sidebar2 /> */}
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              {/* <AppHeader /> */}

              {!loading ? (
                <div className="body flex-grow-1 px-3">
                  <h1
                    className="heading-for-every-page"
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "30px",
                    }}
                  >
                    <span>{`Welcome ${
                      profile?.first_name + " " + profile?.last_name
                    } `}</span>
                  </h1>

                  <div className="row">
                    {data?.length > 0
                      ? data?.map((item, i) => {
                          const loyal_point = item.loyality_system;
                          const pointss = [];
                          pointss.push(loyal_point);
                          const newpoint = pointss?.[0]
                            ?.replaceAll("\n", ",")
                            .split(",");
                          console.log(
                            "loyality pointssssssssssssssssss",
                            newpoint
                          );
                          const sortedData = [...data].sort((a, b) =>
                            parseInt(a.total_points)
                          );
                          return (
                            <>
                              <div className="my-3 col-md-6">
                                <div
                                  className="stats-outer"
                                  style={{
                                    boxShadow:
                                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                                    textAlign: "center",
                                  }}
                                >
                                  <div className="py-2 logo-background">
                                    <h1
                                      style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontFamily: "serif",
                                        margin: "10px 10px",
                                      }}
                                    >
                                      {item?.name}
                                    </h1>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="butn-outer">
                                        <button
                                          className="points-butn mx-1"
                                          onClick={() =>
                                            handleLoyaltyClick("points", i)
                                          }
                                        >
                                          {`Points >`}
                                        </button>
                                      </div>
                                      <img
                                        // src={logo1}
                                        src={item.logo}
                                        alt="logo"
                                        className="mb-2 logo-image"
                                      />
                                      <div className="butn-outer">
                                        <button
                                          className="points-butn mx-1"
                                          onClick={() =>
                                            handleLoyaltyClick("rewards", i)
                                          }
                                        >
                                          {`Rewards >`}
                                        </button>
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>

                                  {selected[i]?.type === "points" ? (
                                    <div
                                      className="card"
                                      style={{
                                        display: "flex",
                                        // justifyContent: "center",
                                        alignItems: "center",
                                        height: "500px",
                                        margin: "10px 0px",
                                        // overflowY: "scroll",
                                      }}
                                    >
                                      <div
                                        className="d-flex relative"
                                        style={{
                                          color: "black",
                                          fontWeight: 600,
                                          margin: "15px 0px",
                                        }}
                                      >
                                        <div
                                          className="mx-4"
                                          style={{ position: "relative" }}
                                        >
                                          <div
                                            style={{
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              height: "130px",
                                              background: "white",
                                              borderRadius: "10px",
                                              padding: "20px",
                                            }}
                                          >
                                            <h3 className="points-title">
                                              Total Earned Points
                                            </h3>
                                            <h2 className="points-number">
                                              {item?.total_points}
                                            </h2>
                                          </div>

                                          <div
                                            style={{
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              height: "130px",
                                              marginTop: "20px",
                                              borderRadius: "10px",
                                              padding: "20px",
                                            }}
                                          >
                                            <h4 className="points-title">
                                              Points You Have Earned This Month
                                            </h4>
                                            <h2 className="points-number">
                                              {item?.monthly_points == ""
                                                ? "0"
                                                : item?.monthly_points}
                                            </h2>
                                          </div>

                                          <div
                                            style={{
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              height: "130px",
                                              marginTop: "20px",
                                              borderRadius: "10px",
                                              padding: "20px",
                                            }}
                                          >
                                            <h4 className="points-title">
                                              Redeemed Points
                                            </h4>
                                            <h2 className="points-number">
                                              {item?.redeem_points == ""
                                                ? "0"
                                                : item?.redeem_points}
                                            </h2>
                                          </div>
                                        </div>

                                        <div
                                          className="mx-4"
                                          style={{ position: "relative" }}
                                        >
                                          <div
                                            style={{
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              height: "130px",
                                              background: "white",
                                              borderRadius: "10px",
                                              padding: "20px",
                                            }}
                                          >
                                            <h4 className="points-title">
                                              Available Points
                                            </h4>
                                            <h2 className="points-number">
                                              {item?.points_earned}
                                            </h2>
                                          </div>
                                          <div
                                            style={{
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              height: "130px",
                                              marginTop: "20px",
                                              borderRadius: "10px",
                                              padding: "20px",
                                            }}
                                          >
                                            <h4 className="points-title">
                                              Points You Have Earned This Year
                                            </h4>
                                            <h2 className="points-number">
                                              {item?.annual_points == ""
                                                ? "0"
                                                : item?.annual_points}
                                            </h2>
                                          </div>
                                          <div
                                            style={{
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              height: "130px",
                                              marginTop: "20px",
                                              borderRadius: "10px",
                                              padding: "20px",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h4 className="points-title">
                                              Bonus Points
                                            </h4>
                                            <h2 className="points-number">
                                              0
                                              {/* {item?.monthly_points == ""
                                                ? "0"
                                                : item?.monthly_points} */}
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="card"
                                      style={{
                                        display: "flex",
                                        // justifyContent: "center",
                                        // alignItems: "center",
                                        height: "500px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      <div
                                        // className="d-flex"
                                        style={{
                                          color: "black",
                                          fontWeight: 600,
                                          margin: "15px 10px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        <div className="mx-3 row">
                                          {newpoint?.map((item) => {
                                            return (
                                              <>
                                                <div className="py-2 px-2 col-md-6">
                                                  <div
                                                    style={{
                                                      boxShadow:
                                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                      height: "130px",
                                                      background: "white",
                                                      borderRadius: "10px",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      padding: "0px 10px",
                                                    }}
                                                  >
                                                    <h5
                                                      style={{
                                                        color: "#3e98c7",
                                                        fontWeight: 700,
                                                        fontSize: "18px",
                                                      }}
                                                    >
                                                      {item
                                                        ? item?.replace(
                                                            "-",
                                                            " "
                                                          )
                                                        : "No Reward Points"}
                                                    </h5>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>

                                        <div className="mx-4"></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })
                      : ""}
                  </div>
                </div>
              ) : (
                <div className="loader-overly">
                  <div className="loader"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
