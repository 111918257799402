import React, { useEffect, useState } from "react";
import {
  getAdminTrails,
  getLocationComplete,
  getTrailNotCompletion,
} from "../../../utils/Api";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Dropdown from "react-bootstrap/Dropdown";

const Membershipdash = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [notCompl, setNotcomp] = useState([]);

  useEffect(() => {
    getTrailCompl();
    // gettrailvisits();
    getNotcompletion();
    getCompleteLocation();
  }, []);

  const getTrailCompl = () => {
    setLoading(true);
    getAdminTrails()
      .then((response) => {
        console.log("get trails completion----------", response);
        if (response?.code == 200) {
          setData(response?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getNotcompletion = () => {
    getTrailNotCompletion()
      .then((res) => {
        // console.log("get trail not completion+++++++++++", res);
        if (res?.code == 200) {
          setNotcomp(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompleteLocation = () => {
    getLocationComplete()
      .then((res) => {
        console.log("getLocationComplete getLocationComplete", res);
        if (res?.code == 200) {
          setLocation(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              {!loading ? (
                <div className="data-table px-5 pb-5">
                  <div>
                    <h1
                      style={{
                        color: "black",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                      }}
                    >
                      Trails
                    </h1>
                  </div>

                  <div className="mt-4">
                    {data?.length > 0
                      ? data?.map((item) => {
                          console.log(
                            "data showwwwwww",
                            typeof data?.[0]?.show
                          );
                          let solo_trail = item.is_solo_trail;
                          const matchedData = data.find(
                            (brewery) => brewery.trail_name === item.trail_name
                          );
                          if (item?.show !== true) {
                            return (
                              <div className="progress-bars mt-3 mb-2">
                                <p style={{ fontWeight: 600 }}>
                                  <span
                                    style={{ color: "black", fontSize: "22px" }}
                                  >{` ${item?.trail_season} ${item?.trail_year}`}</span>
                                </p>
                                <div className="mt-4 d-flex justify-content-center">
                                  <h6>{item?.trails_end_date_and_time}</h6>
                                </div>
                                <div className="row ">
                                  <div className="col-md-4 d-flex justify-content-center">
                                    <div
                                      className="trail-card"
                                      style={{ width: 130, height: 130 }}
                                    >
                                      <CircularProgressbar
                                        strokeWidth={12}
                                        value={item.progress}
                                        text={`${item.progress.toFixed(2)}%`}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <h1
                                      style={{
                                        fontWeight: 600,
                                        color: "black",
                                      }}
                                    >
                                      {item?.trail_name} -
                                      <span> {item?.mini_tour}</span>
                                    </h1>
                                    <div className="row mt-4">
                                      <div className="col-md-6">
                                        <div className="d-flex">
                                          <i
                                            className="bi bi-arrow-right-square-fill me-3"
                                            style={{
                                              marginTop: "5px",
                                              fontSize: "20px",
                                            }}
                                          ></i>
                                          <p
                                            style={{
                                              fontWeight: 600,
                                              fontSize: "20px",
                                            }}
                                          >
                                            {solo_trail === true
                                              ? "Number of Visit -"
                                              : "Breweries Completed -"}
                                          </p>
                                          <h4
                                            style={{
                                              fontWeight: 600,
                                              color: "black",
                                              fontSize: "20px",
                                              marginTop: "5px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {` ${item?.breweries_completed}`}
                                          </h4>
                                        </div>
                                        {solo_trail === false ? (
                                          <div className="d-flex">
                                            <i
                                              className="bi bi-arrow-right-square-fill me-3"
                                              style={{
                                                marginTop: "5px",
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                style={{
                                                  background: "#3e98c7",
                                                }}
                                                id="dropdown-basic"
                                              >
                                                Locations visits
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu
                                                style={{
                                                  height: "250px",
                                                  overflow: "auto",
                                                }}
                                              >
                                                {item?.title_submenu
                                                  ?.breweries_completed
                                                  ?.length > 0
                                                  ? item?.title_submenu?.breweries_completed?.map(
                                                      (item) => {
                                                        return (
                                                          <Dropdown.Item>
                                                            {item?.name}
                                                          </Dropdown.Item>
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <div className="d-flex">
                                          <i
                                            className="bi bi-arrow-right-square-fill me-3"
                                            style={{
                                              marginTop: "5px",
                                              fontSize: "20px",
                                            }}
                                          ></i>
                                          <p
                                            style={{
                                              whiteSpace: "nowrap",
                                              fontWeight: 600,
                                              fontSize: "20px",
                                            }}
                                          >
                                            {solo_trail === true
                                              ? `Visit needed to complete - `
                                              : `Locations to Complete - `}
                                          </p>
                                          <h4
                                            style={{
                                              fontWeight: 600,
                                              color: "black",
                                              fontSize: "20px",
                                              marginTop: "5px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {` ${item?.location_to_complete}`}
                                          </h4>
                                        </div>

                                        {solo_trail === true ? (
                                          ""
                                        ) : (
                                          <div className="d-flex">
                                            <i
                                              className="bi bi-arrow-right-square-fill me-3"
                                              style={{
                                                marginTop: "5px",
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                style={{
                                                  background: "#3e98c7",
                                                }}
                                                id="dropdown-basic"
                                              >
                                                Locations Needed
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                {location?.map((brewery, i) => {
                                                  if (
                                                    brewery.trail_name ===
                                                      item?.trail_name &&
                                                    brewery.brewery_name !== ""
                                                  ) {
                                                    return (
                                                      <Dropdown.Item>
                                                        {brewery.brewery_name}
                                                      </Dropdown.Item>
                                                    );
                                                  } else {
                                                    if (i === 0) {
                                                      return (
                                                        <Dropdown.Item>
                                                          No Data
                                                        </Dropdown.Item>
                                                      );
                                                    }
                                                  }
                                                })}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <div className="progress-bars mt-3 mb-2">
                                  <div className="mt-4 d-flex justify-content-center">
                                    <h6>No Records Found</h6>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })
                      : ""}
                  </div>
                </div>
              ) : (
                <div className="loader-overly">
                  <div className="loader"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membershipdash;
