import Axios from "axios";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL


Axios.defaults.baseURL = API_URL


const local = JSON.parse(localStorage.getItem("app-serve-key"))

const axiosConfig = {
  headers: {
    "Authorization": `Token ${local?.jwtToken}`
  }
}

export const loginUser = async (data) => {
  const response = await axios.post("/adventure/login/", data).then(res => {
    return res?.data
  }).catch(err => {
    return err?.response?.data
  })
  return response
}
export const googleRegister = (data) => {
  const response = Axios.post(`/adventure/googleregister/`, data).then(res => {
    return res?.data
  })
  return response
}

export const getUser = async (data) => {
  const response = await Axios.get(`/adventure/userdata/${data}`).then(res => {
    return res?.data
  }).catch(err => {
    return err.response?.data
  })
  return response
}
export const getProfile = async () => {
  const response = await Axios.get("/adventure/userprofile/", {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}
export const registerUser = async (data) => {
  const response = await Axios.post("/adventure/register/", data).then(res => {
    return res?.data
  }).catch(err => {
    return err.response?.data
  })
  return response
}

export const userForgotPassword = async (data) => {
  const response = await Axios.post("/api/password_reset/", data).then(res => {
    // console.log(res, "try")
    return res?.data
  }).catch(err => {
    return err.response?.data
  })
  return response
}

export const userResetPassword = async (data) => {
  const response = await Axios.post("/api/password_reset/confirm/", data).then(res => {
    return res?.data
  }).catch(err => {
    return err?.response?.data
  })
  return response
}

export const getuserProfile = () => {
  const response = Axios.get(`/adventure/userprofile/`,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}


export const edituserProfile = (id, data) => {
  const response = Axios.post(`/Admin/user/update/${id}`,data,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}

export const getuserlist = (id) => {
  const response = Axios.get(`/Admin/user/edit/${id}`,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}


export const editUserLocation = (id, data) => {
  const response = Axios.post(`/Admin/user/passport/${id}`,data,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}
export const createUser = (data) => {
  const response = Axios.post("/admins/create_user", data).then(res => {
    return res?.data
  })
  return response
}
export const editUser = (data) => {
  const response = Axios.put("/admins/edit_user_detail", data).then(res => {
    return res?.data
  })
  return response
}
export const deleteUser = (id) => {
  const response = Axios.delete(`/admins/delete_user/${id}`).then(res => {
    return res?.data
  })
  return response
}

export const getParticipant = async () => {
  return await Axios.get(`/adventure/participants/points/list/`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Participant list", error);
    });
};

export const getParticipantPoints = async (id) => {
  return await Axios.get(`/adventure/participants/list/${id}`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Participant list", error);
    });
};

export const adminUserList = async () => {
  const response = await Axios.get("/Admin/user/list/", {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}


export const AdminUserStatus = (id,data) => {
  const response = Axios.post(`/Admin/user/status/${id}`, data,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}

export const Adminlistshow = (id,data) => {
  const response = Axios.post(`/Admin/user/listshow/${id}`, data,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}


export const Adminlistexport = (id,data) => {
  const response = Axios.post(`/Admin/user/listexport/${id}`, data,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}

export const searchPassport = ( data) => {
  const response = Axios.post(`/craft/passport/name/`,data,{
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    }
  }).then(res => {
    return res?.data
  })
  return response
}

export const getUserData = (id) => {
  const response = Axios.get(`/admins/get_user_detail/${id}`).then(res => {
    return res?.data
  })
  return response
}

export const getUserByToken = async (token) => {
  const response = await Axios.get(`/admins/token_verification`, {
    headers: {
      "x-access-token": token
    }
  }).then(res => {
    if (res?.data.code == 200) {
      return res?.data
    }
  }).catch((error)=>{
    return error.response
  })
  return response
}


export const deletUser = async(id) => {
  const response = await Axios.delete(`/adventure/delete_user/${id}`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  }).then(res => {
    return res?.data
  }).catch(err => {
    return err.response.data
  })
  return response
};
export const requestPassword = async (token) => {
  const response = await Axios.post(`/admins/token_verification`, {
    headers: {
      "x-access-token": token
    }
  }).then(res => {
    if (res?.data.code == 200) {
      return res?.data.result
    }
  })
  return response
}
// for admin
export const getBreweryAdmin = async (id) => {
  return await Axios.get(`/adventure/breweries/list/${id}`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      // console.log("Participant list", error);
    });
};

export const getTrailsAdmin = async (id) => {
  return await Axios.get(`/adventure/trail/list_id/${id}`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      // console.log("Participant list", error);
    });
};
export const getVisitListadmin = async (id) => {
  return await Axios.get(`/adventure/visit/list/${id}`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      // console.log("Participant list", error);
    });
};


export const getParticipantPointsAdmin = async (id) => {
  return await Axios.get(`/adventure/participants/points/list_id/${id}`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      // console.log("Participant list", error);
    });
};


export const sendOTP = (data) => {
  const response = Axios.put("/users/send_otp", data).then(res => {
    return res?.data
  })
  return response
}


export const verifyOtp = (data) => {
  const response = Axios.put("/users/verify_otp", data).then(res => {
    return res?.data
  })
  return response
}


export const forgotPassword = (data) => {
  const response = Axios.put("/users/forgot_password", data).then(res => {
    return res?.data
  })
  return response
}


export const getAdminTrails = async () => {
  return await Axios.get(`/adventure/trail/list/`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Participant list", error);
    });
};
export const getTrailNotCompletion = async () => {
  return await Axios.get(`/adventure/trails/notcompletion/`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Participant list", error);
    });
};

export const getLocationComplete = async () => {
  return await Axios.get(`/adventure/trails/location/complete/`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Brewery list", error);
    });
};

export const getupdateParticipant = async () => {
  return await Axios.get(`adventure/participants/list/`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Brewery list", error);
    });
};

export const getUserCity = async () => {
  return await Axios.get(`/adventure/trails/user/city/`, {
    headers: {
      "Authorization": `Token ${localStorage.getItem("token")}`
    },
  })
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("Brewery list", error);
    });
};