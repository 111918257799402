/* eslint-disable jsx-a11y/anchor-is-valid */
// import {FC} from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { useEffect, useState } from 'react'
import { getUserCity, getUserData } from '../../../../utils/Api'

const HeaderUserMenu = () => {
  const [userProfile, setUserProfile] = useState({})
  const { auth, logout } = useAuth()
  const [data, setData] = useState([])
  const getuserList =()=>{
    getUserCity().then((res)=>{
      console.log("response----------- user city",res)
      if(res.code === 200){
        setData(res.data)
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    getuserList()
  },[])

  return (
    <>
   
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      
      <div className='menu-item px-3'>
      
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {auth?.firstname} {auth?.lastname}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7' style={{ overflowWrap: "anywhere" }}>
              {auth?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

    

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
    </>
  )
}

export { HeaderUserMenu }
