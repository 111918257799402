/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl'
// import {KTIcon} from '../../../../helpers'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
// import { KTIcon } from '../../../../helpers'
import { getuserProfile } from "../../../../../utils/Api";

const SidebarMenuMain = () => {
  const intl = useIntl()

  const [listShow , setListShow] = useState("")

  useEffect(() => {
    const status = localStorage.getItem("status")
    if(status !== "true"){
    }    
    getuserdata()
  }, []);

  const getuserdata = ()=>{
    getuserProfile().then((res)=>{
      setListShow(res?.data?.[0]?.listshow)
    }).catch((error)=>{
      console.log(error)
    })
  }

  const status = localStorage.getItem("status")

  return (
    <>
    {
      status == "true" ?
      <>
      <SidebarMenuItem
      to='/admin-dashboard'
      icon='element-11'
      title={intl.formatMessage({ id: 'Admin Dashboard' })}
      fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
      to='/user-list'
      icon='bi bi-list-columns-reverse'
      title={intl.formatMessage({ id: 'User List' })}
      fontIcon='bi-archive'
      />
      </>
      :
    <>
     <SidebarMenuItem
        to='/dashboard'
        icon='bi bi-hand-thumbs-up-fill'
        title={intl.formatMessage({ id: 'Loyalty Points' })}
        fontIcon='bi-archive'
      />
       <SidebarMenuItem
        to='/trails'
        icon='bi bi-list-columns-reverse'
        title={intl.formatMessage({ id: 'Trails' })}
        fontIcon='bi-archive'
      />
       <SidebarMenuItem
        to='/update-account'
        icon='profile-circle'
        title={intl.formatMessage({ id: 'Update Info Account' })}
        fontIcon='bi-person'
      />
       <SidebarMenuItemWithSub to='/report' title='Report an Issue' fontIcon='bi-archive' icon='bi bi-list-columns-reverse'>
        <SidebarMenuItem to='/report/missing-points' title='Missing Points' hasBullet={true} />
        </SidebarMenuItemWithSub>      
    </>
    } 
    </>
  )
}

export { SidebarMenuMain }
