
import { useState, useEffect } from "react";
import { getBreweryAdmin } from "../../../utils/Api";

import DynamicTable from "../../table";

const BreweryTable = ({ passport }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open_modal, setOpenModal] = useState({ toggle: false, data: null });

  useEffect(() => {
    setLoading(true);
    getBreweryAdmin(passport)
      .then((res) => {
        setLoading(false);
        if (res.code === 200) {
          setList(res?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  
  
 
  return (
    <>
      <div>
        <div>
        
          {loading ? (
            <div className="loader-overly">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {list && list.length > 0 ? (
                <DynamicTable data={list} />
              ) : (
                "No Record Found!!"
              )}
            </>
          )}
        </div>
      </div>
       </>
  );
};


export default BreweryTable;
