import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useEffect, useState } from 'react'
import { getUserCity, getUserData } from '../../../../utils/Api'
import { useAuth } from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const { config } = useLayout()
  const { auth } = useAuth();
  const [data, setData] = useState([])

  const getuserList =()=>{
    getUserCity().then((res)=>{
      console.log("response----------- user city",res)
      if(res.code === 200){
        setData(res.data)
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    getuserList()
  },[])

  return (
    <div className='app-navbar flex-shrink-0'>
      {data?.map((item)=>{
      if(item.city_link !== "www.explore-.com"){
        return(
          // <button onClick={()=>handleCityLink(item.city_link)} className='btn btn-primary text-white mx-3'>{item.city_name}</button>
          <a href={`http://${item.city_link}`} target='_blank'  className='btn btn-primary explore-butn text-white mx-3'>{item.city_name}</a>
        )
      }
    })}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
