import React from 'react'

const Missingpoint = () => {
  return (
    <div>
       <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <div className="data-table px-5 py-4">
                  <iframe   src="https://forms.fillout.com/t/1qCNb74Wsaus" width="100%" height="1070"></iframe>
                  </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Missingpoint
